import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Amsterdam',
      fontSize: 80,
      color: '#FFFFFF',
      textShadow: "3px 3px 3px #2e766d",
    },
    h2: {
      fontFamily: 'Amsterdam',
      fontSize: 60,
      color: '#FFFFFF',
      textShadow: "3px 3px 3px #2e766d",
    },
    h3: {
      fontFamily: 'Ardent',
      fontSize: 25,
      color: '#c36229'
    },
    h5: {
      fontFamily: 'Ardent',
      fontSize: 14,
      color: '#FFFFFF'
    },
    h4: {
      fontFamily: 'Ardent',
      fontSize: 30,
      color: '#FFFFFF'
    },
  }
});