import confetti from "canvas-confetti";

export function throwConfetti(): void {
  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};

function fire(particleRatio: number, opts: { [key: string]: number }) {
  const count = 200
  confetti(Object.assign({}, opts, {
    particleCount: Math.floor(count * particleRatio),
    origin: {y: 0.6},
  }));
}