import MintWrapper from "./mint/MintWrapper";
import Typography from "@material-ui/core/Typography";
import { ImageCarousel } from "./Carousel";
import ParticlesBackground from "./particles/Particles";
import { Box, Grid, ThemeProvider } from '@material-ui/core';
import { theme } from "./theme";
import './App.css';
import { FC, ReactNode } from "react";

const OnlyLargeScreenGrid: FC<{ children?: ReactNode }> = ({children}) => {
  return (
    <Grid component={Box} display={{ xs: "none", sm: "none", md: "none", lg: "none", xl: "inline" }} item xs={12}>
      {children}
    </Grid>
  )
}

const OnlyOtherScreenGrid: FC<{ children: ReactNode }> = ({children}) => {
  return (
    <Grid component={Box} display={{ xs: "inline", sm: "inline", md: "inline", lg: "inline", xl: "none" }} item xs={12}>
      {children}
    </Grid>
  )
}

const App = () => {
  return (
    <div className="App">
      <ParticlesBackground/>
      <ThemeProvider theme={theme}>
        <div>
          <Grid container spacing={5} justifyContent="center" alignItems="center">
            <OnlyLargeScreenGrid>
              <Typography variant="h1" align={'center'}>Become a member of the anthill...</Typography>
            </OnlyLargeScreenGrid>
            <OnlyLargeScreenGrid></OnlyLargeScreenGrid>
            <OnlyOtherScreenGrid>
              <Typography variant="h2" align={'center'}>Become a member of the anthill...</Typography>
            </OnlyOtherScreenGrid>
            <Grid item xs={12}>
              <MintWrapper></MintWrapper>
            </Grid>
            <OnlyLargeScreenGrid></OnlyLargeScreenGrid>
            <Grid item xs={12}>
              <ImageCarousel></ImageCarousel>
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default App;
