import Carousel from 'react-material-ui-carousel';
import CarouselSlide from 'react-material-ui-carousel';
import { Card, CardMedia, Box, Grid } from "@material-ui/core";

import img1 from "./images/carousel/750/1.png"
import img2 from "./images/carousel/750/2.png"
import img3 from "./images/carousel/750/3.png"
import img4 from "./images/carousel/750/4.png"
import img5 from "./images/carousel/750/5.png"
import img6 from "./images/carousel/750/6.png"
import img7 from "./images/carousel/750/7.png"
import img8 from "./images/carousel/750/8.png"
import img9 from "./images/carousel/750/9.png"
import img10 from "./images/carousel/750/10.png"
import img11 from "./images/carousel/750/11.png"
import img12 from "./images/carousel/750/12.png"
import img13 from "./images/carousel/750/13.png"
import img14 from "./images/carousel/750/14.png"
import img15 from "./images/carousel/750/15.png"
import { FC } from "react";

export const ImageCarousel = ({}: {}) => {

  const pictures = [
    {image: img1},
    {image: img2},
    {image: img3},
    {image: img4},
    {image: img5},
    {image: img6},
    {image: img7},
    {image: img8},
    {image: img9},
    {image: img10},
    {image: img11},
    {image: img12},
    {image: img13},
    {image: img14},
    {image: img15},
  ];

  return (
    <div>
      <OtherScreenCarousel images={pictures}></OtherScreenCarousel>
      <LargeScreenCarousel images={pictures}></LargeScreenCarousel>
    </div>
  );
};

const LargeScreenCarousel: FC<{images: { image: string }[]}> = ({images}) => {
  return (
    <Box width="400px" height="400px" margin="auto" display={{ xs: "none", sm: "none", md: "none", lg: "none", xl: "block" }}>
      <Carousel autoPlay animation={"slide"} duration={150} interval={2000} indicators={false}>
        {images.map(({image}) => (
          <CarouselSlide key={image}>
            <Card style={{ border: "5px solid white" }}>
              <CardMedia
                component='img'
                image={image}
                style={{
                  height: 400,
                  width: 400
                }}
              />
            </Card>
          </CarouselSlide>
        ))}
      </Carousel>
    </Box>
  )
}

const OtherScreenCarousel: FC<{images: { image: string }[]}> = ({images}) => {
  return (
    <Box width="300px" height="300px" margin="auto" display={{ xs: "block", sm: "block", md: "block", lg: "block", xl: "none" }}>
      <Carousel autoPlay animation={"slide"} duration={150} interval={2000} indicators={false}>
        {images.map(({image}) => (
          <CarouselSlide key={image}>
            <Card style={{ border: "5px solid white" }}>
              <CardMedia
                component='img'
                image={image}
                style={{
                  height: 300,
                  width: 300
                }}
              />
            </Card>
          </CarouselSlide>
        ))}
      </Carousel>
    </Box>
  )
}